import { NextRouter } from "next/router";
import React from "react";
import { StoreInterface } from "../assets/interfaces";
import { toBase64 } from "../assets/js/utils/functions";
import { actionIsAllowed, routeIsAllowed, SCOPES } from "../assets/js/utils/permissions";
import routes from "../assets/js/utils/routes";
import { getUserRole } from "./auth-context";

interface RouteGuardPrams {
  currentPath: string;
  user: any;
  storeIndex: number;
  settingUp: React.MutableRefObject<boolean>;
  setRedirectTo: (path: string) => void;
  setPageIsReady: (state: boolean) => void;
  router: NextRouter;
}

function routeGuard({
  currentPath,
  user,
  storeIndex,
  settingUp,
  setRedirectTo,
  setPageIsReady,
  router,
}: RouteGuardPrams) {
  currentPath = currentPath.split("?")[0];

  const { userPaths, authPaths } = routes;
  let redirectPath;

  if (!userPaths.includes(currentPath) && !authPaths.includes(currentPath)) {
    return null;
  }

  const userHasStores = user?.stores.length > 0;
  const store: StoreInterface = userHasStores ? user?.stores[storeIndex] : null;
  const userHasAdditionalStoreDetails = store?.business_category?.name && store?.business_category?.type;
  const userHasUploadedProducts = store?.item_count > 0;
  const userHasSubscribed = store?.subscription ?? false;
  const userHasCompletedSetup = userHasStores && userHasUploadedProducts && userHasSubscribed;

  if (!userHasCompletedSetup) {
    settingUp.current = true;
  }

  if (localStorage.token) {
    if (!userHasStores) {
      redirectPath = "/setup/create-store";
    } else if (!userHasAdditionalStoreDetails && !userHasUploadedProducts) {
      redirectPath = "/setup/additional-store-details";
    } else if (!userHasUploadedProducts) {
      // console.log("Hit user check")
      // console.log({ userHasUploadedProducts })
      //branching in here is neccessary to prevent other conditions from being triggered
      if (currentPath !== "/setup/add-products" && !user.meta?.has_watched_onboarding_video) {
        redirectPath = "/setup/walkthrough";
      }
    } else if (!userHasSubscribed && currentPath !== "/setup/pick-plan") {
      if (currentPath !== "/setup/pick-plan") {
        // console.log(first)
        redirectPath = "/setup/progress";
      }
    } else if (
      (currentPath === "/setup/create-store" && userHasStores) ||
      (currentPath === "/setup/additional-store-details" && userHasAdditionalStoreDetails) ||
      (currentPath === "/setup/add-products" && userHasUploadedProducts) ||
      (currentPath === "/setup/pick-plan" && userHasSubscribed) ||
      (currentPath === "/setup/progress" && userHasCompletedSetup) ||
      (currentPath === "/setup/success" && settingUp.current === false)
    ) {
      //  add comment back
      redirectPath = "/dashboard";
    } else if (authPaths.includes(currentPath)) {
      redirectPath = "/dashboard";
    } else if (currentPath === "/create-store") {
      const subscription = user?.subscription;
      const storesOwnedByUser = user?.stores.filter((s) => s.owner === user?.id);
      const planMessage = toBase64("You'll need to be on the business+ plan to create a new store");
      if (storesOwnedByUser.length > 0 && !subscription) {
        setRedirectTo(currentPath);
        redirectPath = `/my-store/change-plan?context=user&message=${planMessage}`;
      }
      if (
        subscription &&
        !actionIsAllowed({
          planPermission: SCOPES.PLAN_PERMISSIONS.CAN_OWN_MULTIPLE_STORES,
          plan: subscription?.plan?.type,
        })
      ) {
        setRedirectTo(currentPath);
        redirectPath = `/my-store/change-plan?context=user&message=${planMessage}`;
      }
    } else {
      const userRole = getUserRole(store, user);
      const isAllowed = routeIsAllowed({
        route: currentPath,
        userRole,
        plan: store?.subscription?.plan?.type,
        hasEnabledPayments: (store?.payments_enabled && store?.kyc_approved) ?? false,
        hasEnabledDeliveries: store?.deliveries_enabled ?? false,
      });

      if (!isAllowed.allow && !isAllowed.allowPayments) {
        redirectPath = "/payments";
      }

      if (!isAllowed.allow && !isAllowed.allowDeliveries) {
        redirectPath = "/deliveries";
      }

      if (!isAllowed.allow && !isAllowed.role) {
        redirectPath = "/dashboard";
      }

      if (!isAllowed.allow && !isAllowed.plan) {
        setRedirectTo(currentPath); //path user was attempting to visit
        redirectPath = `/my-store/change-plan?message=${toBase64(isAllowed.message)}`;
      }
    }
    setPageIsReady(true);
  } else if (userPaths.includes(currentPath)) {
    setRedirectTo(currentPath);
    redirectPath = "/login";
  }

  if (redirectPath && redirectPath !== currentPath) {
    router.push(redirectPath);
  }
}

export default routeGuard;
